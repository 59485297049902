import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Typography from '../Typography';
import useStyles from './Title.style';

const Title = props => {
  const { text, className, variant } = props;
  const classes = useStyles();

  return text && text.value ? (
    <Typography
      variant={variant}
      component="div"
      classes={{
        root: `${classes.root} ${className}`,
      }}
    >
      <Text field={text} />
    </Typography>
  ) : null;
};

Title.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: '',
  text: { value: '' },
  variant: 'body2',
};

export default Title;
