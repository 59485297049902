import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '../../../components/atoms/Typography';
import useStyles from './style.js';
import ImageVideoComponent from '../ImageVideoComponent';
import { isEmpty } from 'lodash';

const ArticleCarousel = ({
  aspectRatio,
  sitecoreContext,
  pageName,
  componentName,
  heading,
  title,
  mediaGallery,
}) => {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('fadeIn');
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);

  const changeImage = index => {
    setTimeout(() => {
      setImageIndex(index);
    }, 350);
    imageIndex !== index && setAnimationClass('fadeOut');
    setTimeout(() => {
      setAnimationClass('fadeIn');
    }, 300);
  };

  const Slider = () => {
    return mediaGallery && !isEmpty(mediaGallery) && mediaGallery.map((image, index) => (
      <div className={classes.sliderBox} onClick={() => changeImage(index)}>
        <Typography
          variant={isMobile ? 'body5' : 'body1Bold22'}
          className={
            imageIndex === index ? `${classes.font} selected` : classes.font
          }
        >
          <Text field={{ value: `0${index + 1}` }} />
        </Typography>

        <div className={classes.carouselBorder}>
          {imageIndex === index ? (
            <div className={classes.carouselInnerBorder}></div>
          ) : null}
        </div>
      </div>
    ));
  };


  return (
    <div>
      <div className={animationClass && animationClass === 'fadeOut' ? classes.fadeOut : classes.fadeIn}>
        <ImageVideoComponent
          mediaField={mediaGallery[imageIndex].fields}
          aspectRatio={aspectRatio}
          sitecoreContext={sitecoreContext}
          heading={heading}
          title={title}
          pageName={pageName}
          componentName={componentName}
          shouldAutoplay={false}
          articleKey={Math.random()}
        />

      </div>
      <div className={classes.slider}>
        <Slider />
      </div>
    </div>
  );
};

export default ArticleCarousel;