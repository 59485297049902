import { makeStyles } from '@material-ui/core';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',

    '& .caption-text': {
      position: 'absolute',
      bottom: 0,
      ...theme.mixins.marginRight(pxToRem(26)),
      ...theme.mixins.marginLeft(pxToRem(26)),
      marginBottom: pxToRem(26),
      color: theme.palette.common.white,
      ...theme.palette.common.ellipsis(1),
      '.rowReverse &': {
        ...theme.mixins.right(0),
      },
      [theme.breakpoints.down('md')]: {
        ...theme.mixins.marginRight(pxToRem(24)),
        ...theme.mixins.marginLeft(pxToRem(24)),
        marginBottom: pxToRem(10),
      },

      '& .text-line': {
        fontWeight: 400,
        ...theme.palette.common.ellipsis(1),
        [theme.breakpoints.down('md')]: {
          ...theme.palette.common.ellipsis(2),
          fontSize: pxToRem(14),
          lineHeight: pxToRem(16),
        },
      }
    },
  }

}));

export default useStyles;