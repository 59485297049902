import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&.MuiTypography-root': {
      position: 'relative',
      marginBottom: theme.spacing(1),
    },
  },
}));

export default useStyles;
