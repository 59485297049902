import makeStyles from '@material-ui/core/styles/makeStyles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  slider: {
    display: 'flex',
    gap: '24px',
    marginTop: pxToRem(56),
    [theme.breakpoints.down('lg')]: {
      gap: '12px',
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
      marginTop: pxToRem(32),
    },
  },

  carouselBorder: {
    backgroundColor: '#DDDEDE',
    height: pxToRem(2),
    width: pxToRem(200),
    [theme.breakpoints.down('lg')]: {
      width: pxToRem(72.75),
    },
  },

  carouselInnerBorder: {
    backgroundColor: '#009086',
    height: '2px',
    width: pxToRem(104),
    [theme.breakpoints.down('lg')]: {
      width: pxToRem(38),
    },
  },

  sliderBox: {
    gap: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      gap: '8px',
    },
  },
  font: {
    fontWeight: 400,
    color: '#AAAAAA',
    '&.selected': {
      color: '#000000',
    },
    '&:hover': {
      color: '#000000',
    },
  },

  fadeIn: {
    opacity: 1,
    transition: 'all 0.5s ease-in-out',
  },

  fadeOut: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    animationDelay: '-250ms',
  },
}));

export default useStyles;