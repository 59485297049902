import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const getPlayIcon = playIcon => {
  const playIconProperties = {
    background: `url(${playIcon})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: pxToRem(50),
  };

  if (playIcon) {
    return {
      ...playIconProperties,
      '&:hover': {
        ...playIconProperties,
      },
    };
  }
  return {};
};

const useStyles = makeStyles(theme => ({
  root: ({ playIcon }) => ({
    position: 'relative',
    '&.video-picture-tag .pictureWithPoster': {
      paddingTop: '56.25%',
    },
    '& .pictureWithPoster': {
      position: 'absolute',
      width: '100%',
    },
    '& .plyr': {
      '&.plyr--video': {
        background: 'transparent',
      },
      '& > .plyr__control--overlaid': {
        opacity: 1,
        width: theme.spacing(6),
        height: theme.spacing(6),
        background: 'transparent',
        border: playIcon ? 0 : theme.borders.divider,
        borderColor: theme.mixins.White(),
        zIndex: '10',
        transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',

        '&:hover': {
          backgroundColor: 'transparent',
        },

        ...getPlayIcon(playIcon),

        '&.plyr__control': {
          padding: 0,
          borderWidth: pxToRem(2),
          [theme.breakpoints.up('lg')]: {
            top: '50%',
            marginTop: `-${theme.spacing(13.5 / 2)}`,
            width: theme.spacing(13.5),
            height: theme.spacing(13.5),
            transform: 'translateX(-50%)',
            '&:hover': {
              background: theme.mixins.Black(500),
              transition: 'background 0.3s ease-in-out',
            },
          },
        },
        '& svg': {
          width: pxToRem(18),
          height: pxToRem(18),
          margin: 'auto',
          display: playIcon ? 'none' : '',
        },
        '&.plyr__control svg': {
          [theme.breakpoints.up('lg')]: {
            height: theme.spacing(5),
            width: theme.spacing(5),
          },
        },
      },
      '& iframe': {
        opacity: 0,
        visibility: 'hidden',

        [theme.breakpoints.up('lg')]: {
          height: '100vh',
        },
      },

      '& .plyr__video-wrapper': {
        background: 'transparent',
        height: '100%',
        '& .plyr__poster': {
          backgroundSize: 'cover',
          opacity: '0 !important',
        },
      },
    },

    '&.DualHeroSlider': {
      '& .plyr': {
        '&.plyr--video': {
          background: 'transparent',
        },
        '& > .plyr__controls': {
          display: 'none'
        },
        '& > .plyr__control--overlaid': {
          opacity: 0,
          visibility: 'hidden',
          width: theme.spacing(6),
          height: theme.spacing(6),
          background: 'transparent',
          border: playIcon ? 0 : theme.borders.divider,
          borderColor: theme.mixins.White(),
          zIndex: '10',
          transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97)',

          '&:hover': {
            backgroundColor: 'transparent',
          },

          ...getPlayIcon(playIcon),

          '&.plyr__control': {
            padding: 0,
            borderWidth: pxToRem(2),
            [theme.breakpoints.up('lg')]: {
              top: '50%',
              marginTop: `-${theme.spacing(13.5 / 2)}`,
              width: theme.spacing(13.5),
              height: theme.spacing(13.5),
              transform: 'translateX(-50%)',
              '&:hover': {
                background: theme.mixins.Black(500),
                transition: 'background 0.3s ease-in-out',
              },
            },
          },
          '& svg': {
            width: pxToRem(18),
            height: pxToRem(18),
            margin: 'auto',
            display: playIcon ? 'none' : '',
          },
          '&.plyr__control svg': {
            [theme.breakpoints.up('lg')]: {
              height: theme.spacing(5),
              width: theme.spacing(5),
            },
          },
        },
        '& iframe': {
          opacity: 0,
          visibility: 'hidden',

          // [theme.breakpoints.up('lg')]: {
          //   height: '100vh',
          // },
        },

        '& .plyr__video-wrapper': {
          background: 'transparent',
          paddingBottom: '56.25%', /* Aspect ratio 16:9 */
          height: '100%',

          '& iframe': {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
          },

          '& .plyr__poster': {
            backgroundSize: 'cover',
            opacity: '0 !important',
          },
        },
      },
    },

    '& .plyr__element--hide': {
      display: 'none',
    },

    '& .plyr--video': {
      '&.video-stopped .plyr__poster': {
        opacity: '1',
      },
    },
    '& .close-btn': {
      color: theme.mixins.Black(),
      position: 'absolute',
      top: theme.spacing(3),
      ...theme.mixins.right(theme.spacing(3)),
      zIndex: '10',
      width: pxToRem(40),
      height: pxToRem(40),
      background: theme.mixins.White(900),
      borderRadius: borderRadius.circle,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        top: theme.spacing(4),
        ...theme.mixins.right(theme.spacing(4)),
        width: pxToRem(56),
        height: pxToRem(56),
      },

      '&:hover': {
        borderColor: 'transparent',
        background: theme.mixins.White(),
      },

      '& svg': {
        height: theme.spacing(2),
        width: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(4),
          width: theme.spacing(4),
        },
      },

      '&.small': {
        top: theme.spacing(2),
        ...theme.mixins.right(theme.spacing(2)),
        height: theme.spacing(4),
        width: theme.spacing(4),
        [theme.breakpoints.up('lg')]: {
          top: theme.spacing(3),
          ...theme.mixins.right(theme.spacing(3)),
          height: theme.spacing(6),
          width: theme.spacing(6),
        },

        '& svg': {
          [theme.breakpoints.up('lg')]: {
            height: theme.spacing(3),
            width: theme.spacing(3),
          },
        },
      },

      '&.plyr__element--hide': {
        display: 'none',
      },
    },
  }),
  '& .DualHeroSlider': {
    '& > .plyr__control--overlaid': {
      opacity: 0,
      display: 'none'
    },

    '& > .plyr__control--overlaid--ios:not(.no-positioning)': {
      opacity: 0,
      display: 'none'
    }
  },
}));

export default useStyles;
