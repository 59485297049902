import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
const useStyles = makeStyles(theme => ({
  richTextWrap: {
    '& h1': {
      ...theme.typography.h1,
      marginTop: pxToRem(32),
      marginBottom: pxToRem(32),
    },
    '& h2': {
      ...theme.typography.h2,
      marginTop: pxToRem(32),
      marginBottom: pxToRem(32),
    },
    '& h3': {
      ...theme.typography.h3,
      marginTop: pxToRem(32),
      marginBottom: pxToRem(32),
    },
    '& h4': {
      ...theme.typography.h4,
      marginTop: pxToRem(32),
      marginBottom: pxToRem(32),
    },
    '& h5': {
      ...theme.typography.h5,
      marginBottom: pxToRem(16),
    },
    '& h6': {
      ...theme.typography.body1Regular,
      marginBottom: pxToRem(16),
    },
    '& p': {
      ...theme.typography.body1,
      color: theme.palette.text.darkGrey,
      margin: `0 0 ${theme.spacing(2)}`,
    },
    '& ul': {
      color: theme.palette.text.darkGrey,
    },
    '& ol': {
      color: theme.palette.text.darkGrey,
    },
    '& a': {
      ...theme.typography.body1,
      color: theme.palette.common.AbuDhabiOrange,
    },
    '& strong, & b': {
      fontFamily: theme.custom.fontFamily.primaryBold,
    },
  },
}));
export default useStyles;
