import React from 'react';
import { decodeHTML } from '../../../utils/decodeHTML';
import { RichText as RichTextSitecore } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import clsx from 'clsx';
import useStyles from './style';

const RichText = ({ innerHTML, className }) => {
  const classes = useStyles();
  const isEditor = isExperienceEditorActive();
  const htmlWithoutStyle =
    innerHTML &&
    innerHTML.value &&
    innerHTML.value.replace(/\s*style=(["'])(.*?)\1/g, '');

  if (isEditor) {
    return (
      <RichTextSitecore
        className={clsx(
          classes.richTextWrap,
          className ? className : '',
          'richText',
          'richTextSitecore'
        )}
        field={innerHTML}
      />
    );
  }

  if (innerHTML && innerHTML.value) {
    return (
      <div
        className={`${classes.richTextWrap} ${className ? className : ''
          } richText`}
        dangerouslySetInnerHTML={{
          __html: decodeHTML(htmlWithoutStyle),
        }}
      />
    );
  } else {
    return null;
  }
};
export default RichText;
