export const ArticleTextRight = 'Article_TextRight';
export const ArticleTextLeft = 'Article_TextLeft';

export const MultipleImage = 'MultipleImage';
export const FocusLeft = 'FocusLeft';
export const ArticleImageFocus = 'Article_ImageFocus';
export const ArticleTextFocus = 'Article_TextFocus';
export const ArticleTextFocusLeft = 'Article_TextFocusLeft';
export const ArticleTextFocusRight = 'Article_TextFocusRight';

export const LEFT = 'Left';
export const ArticleWithCarousel = 'Article_WithCarousel';
export const ArticleWithCarouselLeft = 'Article_WithCarouselLeft';
export const ArticleWithCarouselRight = 'Article_WithCarouselRight';
